.app-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  .buttons {
    display: flex;
    flex-direction: column;
    margin: 8px;
    position: absolute;
    z-index: 1;
  }
}

.drawer-contents {
  padding: 8px;
}